<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder">{{ $t('event.title') }}
          <span class="text-muted pt-2 font-size-sm d-block">{{ $t('event.events_count', {n: eventTotalCount}) }}</span>
        </h3>
      </div>
    </div>
    <div v-visibility-change="visibilityChange" class="card-body">
      <!-- Фильтры -->
      <div class="row ">
        <div class="col-12 py-3">
          <form>
            <div class="form-row">
              <div class="col">
                <label>{{ $t('event.Name') }}</label>
                <input class="form-control" v-model="filter.name" :placeholder="$t('event.Enter_The_Title')">
              </div>
              <div class="col">
                <label>{{ $t('event.Topics') }}</label>
                <select class="form-control" v-model="filter.topic_id">
                  <option :value="null">{{$t('common.all')}}</option>
                  <option v-for="item in topics" :value="[item.id]" :key="item.id">{{ item.name }}</option>
                </select>
              </div>
              <div class="col">
                <label>{{ $t('event.Space') }}</label>
                <select class="form-control" v-model="filter.location_id">
                  <option :value="allLocationIds">{{$t('common.all')}}</option>
                  <option v-for="item in locationsList" :value="[item.id]" :key="item.id">{{ item.name }}</option>
                </select>
              </div>
              <div class="col">
                <label>{{ $t('event.Date_Beginning') }}</label>
                <date-range-picker
                    ref="picker"
                    :show-dropdowns="true"
                    :linked-calendars="true"
                    :close-on-esc="true"
                    :ranges="false"
                    v-model="dateRange"
                    @update="updateDataRange"
                    control-container-class=""
                    :locale-data="$t('data-range-piker')"
                >
                  <div slot="input" slot-scope="data" style="min-width:250px;" class="input-group">
                    <div v-if="data.startDate" type="text" class="form-control"
                         placeholder="Recipient's username" aria-label="Recipient's username"
                         aria-describedby="basic-addon2">{{ data.startDate | formatDate }} - {{ data.endDate | formatDate }}</div>
                    <div v-else type="text" class="form-control"
                         placeholder="Recipient's username" aria-label="Recipient's username"
                         aria-describedby="basic-addon2">{{ $t('event.Select_Period') }}</div>
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2"><i class="flaticon-event-calendar-symbol"></i></span>
                    </div>
                  </div>
                  <div slot="footer" slot-scope="data" class="drp-buttons">
                    <span data-v-4f8eb193="" class="drp-selected">{{data.rangeText}}</span>
                    <button @click="clickDataRangeCencel(data)" type="button"
                            class="cancelBtn btn btn-sm btn-secondary">{{data.locale.cancelLabel}} </button>
                    <button @click="data.clickApply" v-if="!data.in_selection" type="button"
                            class="applyBtn btn btn-sm btn-success">{{data.locale.applyLabel}} </button>
                  </div>
                </date-range-picker>
              </div>
              <div class="col">
                <label>{{ $t('event.Event_type') }}</label>
                <select class="form-control" v-model="filter.type_id">
                  <option value="">{{$t('common.all')}}</option>
                  <option v-for="type in types" :value="type.id" :key="type.id">{{ type.name }}</option>
                </select>
              </div>
              <div class="col">
                <label>{{ $t('event.Intention') }}</label>
                <select class="form-control" v-model="filter.intention">
                  <option value="null">{{$t('common.all')}}</option>
                  <option v-for="intention in intentions" :value="intention.value" :key="intention.id">{{ intention.name }}</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--begin: Datatable-->
      <core-datatable :loaded="loaded" v-bind:columns.sync=columns
                      :showFooter="true"
                      :items="eventList" v-bind:filter.sync="filter"
                      :totalCount="eventTotalCount">
        <template #name="{ item }">
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <router-link :to="'/events/' + item.id" v-slot="{ href, navigate }">
                <a :href="href" class="text-capitalize" @click="navigate">
                  <img src="@/assets/no_image.png" v-if="item.cover == null" width="75" height="50">
                  <img class="align-self-end" style="border-radius: 4px" v-else :src="item.cover" width="75" height="50">
                </a>
              </router-link>
            </div>
            <div class="ml-4">
              <div class="text-dark-75 text-truncate font-weight-bolder font-size-lg mb-0" style="max-width: 250px;">
                <router-link :to="'/events/' + item.id" v-slot="{ href, navigate }">
                  <a :href="href" class="text-capitalize" @click="navigate">{{ item.name }}</a>
                </router-link>
              </div>
              <div class="text-muted text-truncate" style="max-width: 250px;">
                <span class="separate" v-for="item in item.topics" :key="item.id">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </template>
        <template #location="{ item }" v-if="locationsList.length > 0">
          {{ locationsList.filter(location => location.id == item.location_id)[0].name }}
        </template>
        <template #ts_start="{ item }">
          <span v-if="item.intervals.length > 0">
              {{ item.intervals[0].ts_start | formatDate }}
              <br>
              <span class="text-muted">
                {{ new Date(item.intervals[0].ts_start) | formatDateTime }}
              </span>
            </span>
        </template>
        <template #guests="{ item }">
          <span class="label font-weight-bold label-inline label-light-success" v-if="item.is_user_registered">{{ $t("event.I_Will_Go") }}</span>
          <span class="label font-weight-bold label-inline label-secondary" v-else>{{ $t("event.I_Wont_Go") }}</span>
        </template>
        <template #type="{ item }">
          {{ item.type == 1 ? types[0].name : types[1].name }}
        </template>
        <template #action="{ item }" class="text-right">
          <router-link
            :to="'/events/' + item.id"
            v-slot="{ href, navigate }"
          >
            <!-- v-slot="{{ route + isActive + isExactActive }}" -->
            <a :href="href" @click="navigate" class="btn btn-sm btn-icon btn-light bg-secondary mr-2">
              <font-awesome-icon class="icon-md" icon="eye"/>
            </a>
          </router-link>
          <a class="btn btn-sm btn-icon btn-light bg-secondary mr-2" v-if="!item.is_user_registered" @click="goOn(item)">
            <font-awesome-icon class="icon-md" icon="check"/>
          </a>
          <a class="btn btn-sm btn-icon btn-light bg-secondary mr-2" v-else @click="showDialogOffModal(item)">
            <font-awesome-icon class="icon-md" icon="times"/>
          </a>
        </template>
      </core-datatable>
      <!--end: Datatable-->
    </div>

    <CoreAlert :title="$t('event.Registration_On_Event')"
                :closeButtonTitle="$t('event.Close')"
                closeButtonColor="light-primary"
                icon="ok"
                :show.sync="alertRegistrationOnModal">
      <template>
        <div class="font-weight-bolder">{{ $t('event.Registration_On_Event_Body') }}</div>
        <div class="text-muted">{{ $t('event.Registration_On_Event_Body_Desc') }}</div>
        <span class="text-muted text-capitalize">«{{ itemTemp.name }}»</span>
      </template>
    </CoreAlert>

    <CoreConfirm :title="$t('event.Registration_Off_Event')"
                :closeButtonTitle="$t('event.Close')"
                :okButtonTitle="$t('event.Registration_Off_Confirm_Buttom')"
                :onOk="onRegistrationOff"
                icon="bun"
                :show.sync="alertRegistrationOffModal">
      <template>
        <div class="font-weight-bolder">{{ $t('event.Registration_Off_Event_Body') }}</div>
        <div class="text-muted">{{ $t('event.Registration_Off_Event_Body_Desc') }}</div>
      </template>
    </CoreConfirm>

   <!-- <b-modal v-model="dialogCancelModal"
            size="md"
            @ok="goCancel"
            :title="$t('event.cancel_event')"
            :ok-title="$t('event.yes_cancel')"
            :cancel-title="$t('event.back')">
     <div class="d-flex flex-column align-items-center">
       <div class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
         <div class="symbol-label">
           <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
         </div>
       </div>
       <div class="font-weight-bolder">{{ $t('event.cancel_your_event') }}</div>
     </div>
   </b-modal> -->
  </div>
</template>

<script>
import {mapMutations, mapGetters, mapActions} from 'vuex';
import moment from 'moment';
import api from '@/plugins/api';
import CoreConfirm from '@/components/core/Confirm';
import CoreAlert from '@/components/core/Alert';

// https://innologica.github.io/vue2-daterange-picker/#props
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

let cookie = require('vue-cookie');

let bg = require('@/assets/bg/bg-4.jpg');

export default {
  components: { DateRangePicker, CoreAlert, CoreConfirm },
  computed: {
    ...mapGetters({
      eventList: 'events/list',
      eventPage: 'events/page',
      eventPerPage: 'events/perPage',
      eventTotalCount: 'events/totalCount',
      loaded: 'events/loaded',
      // locations: 'events/locations',
      topics: 'events/topics',
      locationsList: 'spaces/list',
    }),
    allLocationIds() {
      if (this.locationsList && this.locationsList.length > 0) return this.locationsList.map(item => item.id);
    }
  },
  data() {
    return {
      alertRegistrationOnModal: false,
      alertRegistrationOffModal: false,
      dateRange: {},
      loading: false,
      valid: false,
      error: null,
      columns: [
        { text: 'event.columns.id', value: 'id', key: 'id' },
        { text: 'event.columns.Event', slot: 'name', key: 'name', sort: false },
        { text: 'event.columns.Space', slot: 'location', key: 'location_id', sort: false },
        { text: 'event.columns.Date_Beginning', slot: 'ts_start', key: 'ts_start' },
        { text: 'event.columns.Event_Type', slot: 'type', key: 'type', sort: false },
        { text: 'event.columns.Intention', slot: 'guests', key: 'guests' },
        { text: 'event.columns.Actions', slot: 'action', sort: false, class: 'text-right' },
      ],
      subjects: [],
      types: [
        { id: 1, name: this.$t("event.Online_Type") },
        { id: 2, name: this.$t("event.Offline_Type") },
      ],
      intentions: [
        { id: 1, name: this.$t("event.I_Will_Go"), value: true },
        { id: 2, name: this.$t("event.I_Wont_Go"), value: false }
      ],
      filter: {
        name: '',
        topic_id: null,
        type_id: "",
        intention: null,
        sort: [],
        location_id: [],
        from: '',
        to: '',
        page: 1,
        expand: 'location',
        'per-page': 10,
      },
      itemTemp: {
        name: '',
      },
    }
  },
  mounted() {
    this.TopicsList();
  },
  watch: {
    columns: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
    filter: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
    locationsList: {
      handler(locations) {
        if (locations) {
          this.setLocationList();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('events', [
      'LocationList',
      'TopicsList'
    ]),
    setLocationList() {
      let ids = this.locationsList.map(item => item.id);
      this.LocationList(ids.join(',')).then(() => {
        this.filter.location_id = ids;
        this.fetch();
      });
    },
    goOn(item) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.itemTemp = item;
      api.post('/v2/coworking-events/' + item.id + '/guest').then(responce => {
        this.alertRegistrationOnModal = true;
        this.fetch();
        this.loading = false;
      });
    },
    goOff(item) {
      let self = this;
      api.delete('/v2/coworking-events/' + item.id + '/guest').then(responce => {
        // console.log(responce);
        this.fetch()
      });
    },
    alertOnCancel(show) {
      // console.log('show alertOnCancel', show);
    },
    showDialogOffModal(item) {
      this.itemTemp = item;
      this.alertRegistrationOffModal = true;
    },
    onRegistrationOff(show) {
      this.goOff(this.itemTemp);
      // console.log('show onRegistrationOff', show);
    },
    clickDataRangeCencel(data) {
      this.dateRange = {}
      data.rangeText = ''
      this.filter.from  = ''
      this.filter.to  = ''
      data.clickCancel()
    },
    updateDataRange(params) {
      // this.filter.from  = moment.unix(params.startDate).format('X') / 1000;
      // this.filter.to  = moment.unix(params.endDate).format('X') / 1000;
      this.filter.from  = (params.startDate).toDateString();
      this.filter.to  = (params.endDate).toDateString();
    },
    moment: function () {
      return moment();
    },
    fetch() {
      for (let i in this.columns) {
        switch(this.columns[i].sort) {
          case 1:
            this.filter.sort = this.columns[i].key
            break;
          case 2:
            this.filter.sort = '-'+this.columns[i].key
            break;
        }
      }
      this.$store.dispatch('events/GetData', this.filter);
    },
    visibilityChange(evt, hidden){
      if (!hidden) {
        this.fetch();
      }
    },
    showDialogCancelModal() {
      this.dialogCancelModal = true
    },
    goCancel() {
      let self = this
      api.delete('v2/events/' + this.selected.id).then(responce => {
        self.$store.dispatch('events/GetData', self.filter)
        this.dialogCancelModal = false
      })
    },
  }
}
</script>

<style>
.truncate {
  flex: 1;
  min-width: 0; /* or some value */
}
.truncate p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.separate::after {
  content: ', ';
}
.separate:last-child::after {
  content: '';
}
</style>
